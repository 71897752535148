// useGenerateInvoice.ts
import { useState } from "react";
import axios from "axios";
import { Invoice } from "./types";

type GenerateInvoiceResponse = {
  message: string;
  uniqueKey: string;
  expirationToken: string;
};

export const useGenerateInvoice = () => {
  const [data, setData] = useState<GenerateInvoiceResponse | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const generateInvoice = async (invoiceData: Invoice) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.post<GenerateInvoiceResponse>(
        "https://api.factuur4u.nl/generate-invoice",
        invoiceData
      );
      setData(response.data);
    } catch (error) {
      setError(error as Error);
      setData(null);
    } finally {
      setIsLoading(false);
    }
  };

  return { generateInvoice, data, isLoading, error };
};
