import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'; // Import the theme you defined
import Container from '@mui/material/Container';
import App from './InvoiceForm'; // Import your main app component
import { CssBaseline } from '@mui/material';

import './App.css';
import './index.css'

function ThemedApp() {
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <CssBaseline />
        <App />
      </Container>
    </ThemeProvider>
  );
}

export default ThemedApp;