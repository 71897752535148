import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6', // Primary color
    },
    secondary: {
      main: '#19857b', // Secondary color
    },
    error: {
      main: '#ff0000', // Error color
    },
    background: {
      default: '#f0f2f5', // Page background color
    },
    text: {
      primary: '#333333', // Primary text color
      secondary: '#555555', // Secondary text color
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
    button: {
      textTransform: 'none', // Prevents uppercase text for buttons
    },
  },
  spacing: 8, // Base spacing unit
  components: {
    MuiCssBaseline: {
        styleOverrides: `
        //   .grid-padding-1 {
        //     padding: 8px !important;
        //   }
        //   .grid-padding-2 {
        //     padding: 16px !important;
        //   }
        // body {
        //     background: red;
        // }
          // Add more as needed
        `,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '5px', // Button border radius
          padding: '6px 12px', // Button padding
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined', // Default variant for text fields
        InputLabelProps: {
          shrink: true, // Ensures the label doesn't overlap with the value
        },
      },
    },
    // Add more component overrides as needed
});

export default theme;
