import { useState, useCallback, useMemo } from "react";
import React from "react";
import "./App.css";

import {
  Adres,
  LineItem,
  Discount,
  InvoiceNumber,
  VATRates,
  Invoice,
} from "./types";
import { getId } from "./utils";
import { addDays } from "date-fns/fp";

import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  InputAdornment,
  Link,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import { useGenerateInvoice } from "./generateInvoice";
import { useSendInvoice } from "./sendInvoice";

interface EmailData {
  toEmail: string;
  subject: string;
  text: string;
}

function App() {
  const { generateInvoice, data, isLoading, error } = useGenerateInvoice();
  const sendInvoice = useSendInvoice();

  const [formData, setFormData] = useState<EmailData>({
    toEmail: "your@email.com",
    subject: "New Invoice!",
    text: "Please see attached invoice.",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    data?.expirationToken &&
      sendInvoice.sendInvoice({
        ...formData,
        token: data?.expirationToken,
        uniqueKey: data?.uniqueKey,
      });
    alert("verzonden");
  };

  function saveAdres(key: string, value: Adres) {
    localStorage.setItem(key, JSON.stringify(value));
    alert("saved");
  }

  function getInvoiceNumber(): string | number {
    const nmbr = localStorage.getItem('invoiceNmbr');
    return nmbr || 0;
  }

  function getAdres(key: string): Adres | null {
    const adres = localStorage.getItem(key);
    if (!adres) {
      return null;
    }

    try {
      return JSON.parse(adres);
    } catch (e) {
      return null;
    }
  }

  const [sender, setSender] = useState<Adres>(
    getAdres("sender") || {
      name: "",
      street: "",
      number: "",
      zipCode: "",
      city: "",
      email: "",
      VATNumber: "",
      companyNumber: "",
      period: "",
    }
  );

  const [receiver, setReceiver] = useState<Adres>(
    getAdres("receiver") || {
      name: "",
      street: "",
      number: "",
      zipCode: "",
      city: "",
      email: "",
      VATNumber: "",
      companyNumber: "",
      to: "",
    }
  );

  const defaultLineItem: LineItem = {
    id: getId(),
    description: "",
    unitPrice: 0,
    quantity: 0,
    VAT: 21,
  };
  const [items, setItems] = useState<LineItem[]>([defaultLineItem]);

  function addLineItem(lineItem: LineItem) {
    setItems([...items, lineItem]);
  }

  const [discount, setDiscount] = useState<Discount>({
    type: "percentage",
    value: 0,
  });
  const [description, setDescription] = useState<string>("");

  const [invoiceNumber, setInvoiceNumber] = useState<InvoiceNumber>(getInvoiceNumber()); // todo : grab from local storage

  const [date, setDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(addDays(30, new Date()));

  function sendEmail(invoice: Invoice) {
    localStorage.setItem('invoiceNmbr', String(invoiceNumber))
    generateInvoice(invoice);
    alert("verzonden");
  }

  const totals = useMemo(() => {
    const result = items.reduce(
      (acc, item) => {
        const exclVAT = item.quantity * item.unitPrice;
        const disc =
          discount.type === "absolute" ? 0 : exclVAT * (discount.value / 100);
        const VAT = (exclVAT - disc) * (item.VAT / 100);
        const total = exclVAT + VAT;

        return {
          VAT: acc.VAT + VAT,
          total: acc.total + total,
          totalExcludingVAT: acc.totalExcludingVAT + exclVAT,
          discount: acc.discount + disc,
        };
      },
      { VAT: 0, total: 0, totalExcludingVAT: 0, discount: 0 }
    );

    // Return the computed totals
    return {
      total: result.total,
      totalExcludingVAT: result.totalExcludingVAT,
      VAT: result.VAT,
      discount: result.discount,
    };
  }, [items, discount]);

  const invoice = useMemo(
    () => ({
      receiver,
      sender,
      description,
      invoiceNumber,
      date,
      endDate,
      totals,
      lineItems: items,
      discount,
    }),
    [
      receiver,
      sender,
      description,
      invoiceNumber,
      date,
      endDate,
      totals,
      items,
      discount,
    ]
  );

  const updateItemDescription = useCallback(
    (id: string, description: string) => {
      setItems((currentItems) =>
        currentItems.map((item) =>
          item.id === id ? { ...item, description } : item
        )
      );
    },
    []
  );

  const updateItemUnitPrice = useCallback((id: string, unitPrice: number) => {
    setItems((currentItems) =>
      currentItems.map((item) =>
        item.id === id ? { ...item, unitPrice } : item
      )
    );
  }, []);

  const updateItemUnitQuantity = useCallback((id: string, quantity: number) => {
    setItems((currentItems) =>
      currentItems.map((item) =>
        item.id === id ? { ...item, quantity } : item
      )
    );
  }, []);

  const updateItemVAT = useCallback((id: string, VAT: VATRates) => {
    setItems((currentItems) =>
      currentItems.map((item) => (item.id === id ? { ...item, VAT } : item))
    );
  }, []);

  return (
    <Container>
      <Grid container spacing={3} padding={3}>
        <Grid item md={6}>
          <TextField
            fullWidth
            value={sender.name}
            placeholder="Naam van je Bedrijf"
            onChange={(e) => setSender({ ...sender, name: e.target.value })}
          />
          <TextField
            value={sender.street}
            placeholder="Adres"
            onChange={(e) => setSender({ ...sender, street: e.target.value })}
          />
          <TextField
            value={sender.number}
            placeholder="Nummer"
            onChange={(e) => setSender({ ...sender, number: e.target.value })}
          />
          <TextField
            value={sender.zipCode}
            placeholder="Postcode"
            onChange={(e) => setSender({ ...sender, zipCode: e.target.value })}
          />
          <TextField
            value={sender.city}
            placeholder="Stad"
            onChange={(e) => setSender({ ...sender, city: e.target.value })}
          />

          <TextField
            fullWidth
            value={sender.email}
            placeholder="email"
            onChange={(e) => setSender({ ...sender, email: e.target.value })}
          />
          <TextField
            fullWidth
            value={sender.VATNumber}
            placeholder="BTW nummer"
            onChange={(e) =>
              setSender({ ...sender, VATNumber: e.target.value })
            }
          />
          <TextField
            fullWidth
            value={sender.companyNumber}
            placeholder="KVK Nummer"
            onChange={(e) =>
              setSender({ ...sender, companyNumber: e.target.value })
            }
          />
          <TextField
            fullWidth
            value={sender.period}
            placeholder="Periode"
            onChange={(e) => setSender({ ...sender, period: e.target.value })}
          />
          <Button onClick={() => saveAdres("sender", sender)}>
            Gegevens onthouden
          </Button>
        </Grid>
        <Grid item md={6}>
          <TextField
            fullWidth
            value={receiver.name}
            placeholder="Naam ontvangende Bedrijf"
            onChange={(e) => setReceiver({ ...receiver, name: e.target.value })}
          />
          <TextField
            value={receiver.street}
            placeholder="Adres"
            onChange={(e) =>
              setReceiver({ ...receiver, street: e.target.value })
            }
          />
          <TextField
            value={receiver.number}
            placeholder="Nummer"
            onChange={(e) =>
              setReceiver({ ...receiver, number: e.target.value })
            }
          />
          <TextField
            value={receiver.zipCode}
            placeholder="Postcode"
            onChange={(e) =>
              setReceiver({ ...receiver, zipCode: e.target.value })
            }
          />
          <TextField
            value={receiver.city}
            placeholder="Stad"
            onChange={(e) => setReceiver({ ...receiver, city: e.target.value })}
          />

          <TextField
            fullWidth
            value={receiver.VATNumber}
            placeholder="BTW nummer"
            onChange={(e) =>
              setReceiver({ ...receiver, VATNumber: e.target.value })
            }
          />
          <TextField
            fullWidth
            value={receiver.companyNumber}
            placeholder="KVK Nummer"
            onChange={(e) =>
              setReceiver({ ...receiver, companyNumber: e.target.value })
            }
          />
          <TextField
            fullWidth
            value={receiver.to}
            placeholder="tav"
            onChange={(e) => setReceiver({ ...receiver, to: e.target.value })}
          />
          <TextField
            fullWidth
            value={receiver.description}
            placeholder="omschrijving"
            onChange={(e) =>
              setReceiver({ ...receiver, description: e.target.value })
            }
          />
          <Button onClick={() => saveAdres("receiver", receiver)}>
            Gegevens onthouden
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3} padding={3}>
        <Grid item md={9}>
          factuur
        </Grid>
        <Grid item md={3}>
          <TextField
            fullWidth
            value={invoiceNumber}
            label="factuur nummer"
            onChange={(e) => setInvoiceNumber(e.target.value)}
          />
          <DatePicker
            label="datum"
            value={date}
            onChange={(d) => d && setDate(d)}
          />
          <DatePicker
            label="eind datum"
            value={endDate}
            onChange={(d) => d && setEndDate(d)}
          />
        </Grid>
      </Grid>
      <Grid container padding={2}>
        <Grid item md={12}>
          {items.map((item, idx) => (
            <Grid container key={`line-item-${item.id}`}>
              <Grid item md={3}>
                <TextField
                  fullWidth
                  value={item.description}
                  placeholder={`Beschrijving van item #${idx + 1}`}
                  onChange={(e) =>
                    updateItemDescription(item.id, e.target.value)
                  }
                />
              </Grid>
              <Grid item md={2}>
                <TextField
                  type="number"
                  fullWidth
                  value={item.unitPrice || ""}
                  placeholder={`Prijs`}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">€</InputAdornment>
                    ),
                  }}
                  onChange={(e) =>
                    updateItemUnitPrice(item.id, Number(e.target.value))
                  }
                />
              </Grid>
              <Grid item md={2}>
                <TextField
                  type="number"
                  fullWidth
                  value={item.quantity || ""}
                  placeholder={`Aantal`}
                  onChange={(e) =>
                    updateItemUnitQuantity(item.id, Number(e.target.value))
                  }
                />
              </Grid>
              <Grid item md={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">BTW</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={item.VAT}
                    label="Age"
                    onChange={(e) =>
                      updateItemVAT(item.id, Number(e.target.value) as VATRates)
                    }
                  >
                    <MenuItem value={21}>21%</MenuItem>
                    <MenuItem value={9}>9%</MenuItem>
                    <MenuItem value={0}>0%</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={2}>
                <Typography align="right">
                  €
                  {(
                    (item.quantity || 0) *
                    (item.unitPrice || 0) *
                    (item.VAT / 100 + 1)
                  ).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          ))}
          <Grid item>
            <Button
              onClick={() => addLineItem({ ...defaultLineItem, id: getId() })}
              variant="contained"
            >
              Nieuw item
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container padding={2}>
        <Grid item md={6}></Grid>
        <Grid item md={6}>
          <Grid container>
            <Grid item md={9}>
              subtotaal excl btw
            </Grid>
            <Grid item md={3}>
              <Typography align="right">
                €{totals.totalExcludingVAT.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={5}>
              Korting
            </Grid>

            <Grid item md={4}>
              <TextField
                type="number"
                fullWidth
                value={discount.value || ""}
                placeholder="0,0"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                onChange={(e) =>
                  setDiscount({ ...discount, value: Number(e.target.value) })
                }
              />
            </Grid>
            <Grid item md={3}>
              <Typography align="right">
                -€{totals.discount.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item md={9}>
              Totaal
            </Grid>
            <Grid item md={3}>
              <Typography align="right">
                €{(totals.total - totals.discount).toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container padding={2}>
        <Grid item md={12}>
          <TextField
            multiline
            fullWidth
            value={description}
            placeholder="opmerking"
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container padding={2}>
        <Grid item md={8}>
          {isLoading && <p>Loading...</p>}
          {error && <p>Error: {error.message}</p>}
          {data && (
            <>
              <Typography variant="body1">
                Invoice Generated: {data.message}{" "}
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href={`https://api.factuur4u.nl/download-invoice?uniqueKey=${data.uniqueKey}&token=${data.expirationToken}`}
                >
                  download
                </Link>
              </Typography>
              <Grid container>
                <Grid item>
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="To Email"
                          name="toEmail"
                          variant="outlined"
                          value={formData.toEmail}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Subject"
                          name="subject"
                          variant="outlined"
                          value={formData.subject}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Text"
                          name="text"
                          variant="outlined"
                          multiline
                          rows={4}
                          value={formData.text}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Send Email
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        <Grid item md={4}>
          <Button
            variant="contained"
            onClick={() => invoice && sendEmail(invoice)}
          >
            Verzenden
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default App;
