// useGenerateInvoice.ts
import { useState } from "react";
import axios from "axios";

type SendInvoiceResponse = {
  message: string;
};

export const useSendInvoice = () => {
  const [data, setData] = useState<SendInvoiceResponse | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const sendInvoice = async (emailData: {
    uniqueKey: string;
    token: string;
    toEmail: string;
    subject: string;
    text: string;
  }) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.post<SendInvoiceResponse>(
        "https://api.factuur4u.nl/send-invoice",
        emailData
      );
      setData(response.data);
    } catch (error) {
      setError(error as Error);
      setData(null);
    } finally {
      setIsLoading(false);
    }
  };

  return { sendInvoice, data, isLoading, error };
};
